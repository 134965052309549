import React from "react"
import Topic from "../../components/Topic"

const Transportation = () => (
  <Topic id="transportation">
    <p>
      Canada is a big country. Major cities have reasonable public transit
      systems which include buses, subways, streetcars, ferries and other modes
      of transportation. Some cities offer low-income families transit
      assistance. Be sure to look into it! Most programs require you to apply
      every year.
    </p>
    <p>
      Uber, Lyft and other ride-sharing services are available in urban areas as
      are traditional taxis. If you move to a smaller city or more rural
      setting, you will need to have a car. Each province has its own procedures
      for obtaining a driver&apos;s licence or transferring driver&apos;s
      licence from another country. Included here is also information on tips
      for purchasing a used car as well as information related to getting car
      insurance in different provinces.
    </p>
  </Topic>
)

export default Transportation
